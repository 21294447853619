import $ from "jquery";

var pathname = window.location.pathname;
if (pathname === '/' ) {
  $("#calculate").addClass('active');
  $("#dashboard").removeClass('active');
} else {
  $("#dashboard").addClass('active');
  $("#calculate").removeClass('active');
}
